import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import logo from "../../assets/images/logo.png"

const Navbar = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        if (window !== undefined) {
            let elementId = document.getElementById("navbar");
            document.addEventListener("scroll", () => {
                if (window.scrollY > 170) {
                    elementId.classList.add("is-sticky");
                } else {
                    elementId.classList.remove("is-sticky");
                }
            });
        }
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand"
                            >
                                <img src={logo} alt="logo" />
                            </Link>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav margin:auto">
                                    <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Home
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/products"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Products <i className='bx hide-on-md bx-chevron-down'></i>
                                        </Link>
                                        <ul className="dropdown-menu hide-on-md">
                                            <li className="nav-item">
                                                <Link
                                                    to="/products/blood-bank-software"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Blood Banking Management Information System
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/products/patient-blood-management"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Patient Blood Management System
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/products/isbt-128-labeling-system"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    ISBT 128 Labelling System
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/products/electronic-document-management-system"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Electronic Document Management System
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/products/workforce-management-software"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Workforce Management Software
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/products/quality-control-tracking"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Quality Control Management System
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/service-details"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Services
                                        </Link>

                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/about-us"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            About Us
                                        </Link>

                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/blogs/"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link blog"
                                        >
                                            Blogs
                                        </Link>
                                    </li>
                                </ul>
                                
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        <Link
                                            to="/demo"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-right"></i>Free Demo<span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
